import PropTypes from 'prop-types'
import React from 'react'

// import Header from "./header";

function Layout({ children }) {
  return (
    <div className="h-screen py-8 bg-yellow-100">
      <div className="px-4 mx-auto antialiased max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <main>{children}</main>
        </div>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
